<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="product">
        <div class="box2">
            <div v-if="dangqian_leixing" class="dqbm">
                <p>{{chanpin_list[chanpin_list.length-1].name}}</p>
                <div @click="dianji_bianji(chanpin_list[chanpin_list.length-1])">编辑</div>
            </div>
            <div class="box2_mianbaoxie">
                <div v-for="(i,index) in chanpin_list" :key='index' @click="chanpin_dian(i,index)">
                    <p :class="chanpin_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="tit_center">
                <div v-for="(i,index) in tit_list" :key='index' @click="tupian_dianjia(index)">
                    <!-- <template v-if="i.dept_id"> -->
                        <img :src="index == indea?require('../../assets/red_quan.png'):require('../../assets/ccc_quan.png')"  alt="">
                        <p :style="index == indea?'color:#8d52e3;':''">{{i}}</p>
                    <!-- </template> -->
                </div>
            </div>
            <div class="box_nei" v-if="indea==0">
                <div>
                    <p style="color:#fff;background:#8d52e3;border:none;" @click="tianjia_leixing">添加产品种类</p>
                    <p @click="tiaozheng_dalei('大类')">调整产品类型</p>
                    <!-- <p>调整顺序</p> -->
                </div>
                <div>
                    <!-- <p @click="shanchu_chanpin">删除</p> -->
                    <p @click="dianji_tingyong">停用</p>
                </div>
            </div>
            <div class="box_nei" style="background:#F7DEDE;" v-if="indea==1">
                <div>
                    <p style="color:#fff;background:#8d52e3;border:none;" @click="tingyong_leixing">停用产品种类</p>
                    <!-- <p>调整顺序</p> -->
                </div>
                <p @click="dianji_qiyong">启用</p>
            </div>
            <div class="box2_liebiao">
                <ul>
                    <li v-for="(i,index) in qiye_chanpin_list" :key="index" @click="dianji_chanpin(i)">
                        <p class="box2_liebiao_tit">
                            <!-- <input @click.stop ref="quana" type="checkbox" @click="dianji_quanxian(i,$event.path[0].checked,index)"> -->
                            <img :src="i.zhi?require('../../assets/kuang_duigou.png'):require('../../assets/kaung_kongxin.png')" @click.stop="i.zhi=!i.zhi" alt="">
                            {{i.cat_name}}({{i.prod_num}})
                        </p>
                        <div>
                            <p @click.stop='shangyi(i,index)'><i class="el-icon-top"></i>上移</p>
                            <p @click.stop='xiayi(i,index)'><i class="el-icon-bottom"></i>下移</p>
                            <!-- <p @click="dianji_bianji(i)">编辑</p> -->
                            <!-- <p :style="indea==1?'color:#ccc;':''" @click="dianji_chanpin(i)">进入子产品</p> -->
                        </div>
                        <!-- <img class="img1" :src="i.child_flag=='1'?require('../../assets/chazi_ok.png'):require('../../assets/chazi.png')" alt=""> -->
                    </li>
                </ul>
            </div>
            <div class="box_nei" v-if="indea==0">
                <div>
                    <p style="color:#fff;background:#8d52e3;border:none;" @click="tianjia_chanpin_btn">添加产品</p>
                    <p @click="tiaozheng_dalei('小类')">调整产品类型</p>
                    <p @click="jinru_daoru">导入</p>
                    <p @click="jinru_daochu">导出</p>
                    <p @click="chanpin_shangyi">上移</p>
                    <p @click="chanpin_xiayi">下移</p>
                    <p class="sousuo_box">
                        <i class="el-icon-search"></i>
                        <input type="text" v-model="sousuo_chanpin_name" @keyup.enter='chaxun_name'>
                        <i @click="sousuo_chanpin_name='',chaxun_name()" class="el-icon-circle-close"></i>
                    </p>
                </div>
                <div>
                    <!-- <p @click="piliang_shanchu">删除</p> -->
                    <p @click="piliang_tingyong" style="margin-right:0;">停用</p>
                </div>
            </div>
            <div class="box_nei" style="background:#F7DEDE;" v-if="indea==1">
                <div>
                    <!-- <p style="color:#fff;background:#8d52e3;border:none;" @click="tingyong_chanpin_btn">停用产品</p> -->
                    <!-- <p @click="jinru_daoru">导入</p>
                    <p @click="jinru_daochu">导出</p> -->
                    <p @click="chanpin_shangyi">上移</p>
                    <p @click="chanpin_xiayi">下移</p>
                </div>
                <div>
                    <!-- <p @click="piliang_shanchu">删除</p> -->
                    <p @click="piliang_qiyong" style="margin-right:0;">启用</p>
                </div>
            </div>
            <el-table @row-click='hangdian' :cell-style="liebiao" :header-cell-style="biaotou" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="42">
                </el-table-column>
                <el-table-column label="图片">
                    <template slot-scope="props">
                        <img style="width:0.5rem;height:0.5rem;" :src="props.row.prod_pic" alt="" v-if="props.row.prod_pic!=null && props.row.prod_pic != '' && props.row.prod_pic && props.row.prod_pic != '-'" />
                        <div v-else>-</div>
                    </template>
                </el-table-column>
                <el-table-column  prop="prod_code" label="产品编号">
                </el-table-column>
                <el-table-column  prop="prod_name" label="产品">
                </el-table-column>
                <el-table-column  prop="site_name" label="仓库">
                </el-table-column>
                <el-table-column prop="cat_name"  label="类型">
                </el-table-column>
                <el-table-column  prop="spec" label="规格">
                </el-table-column>
                <el-table-column  prop="type" label="型号">
                </el-table-column>
                <el-table-column  prop="unit" label="单位">
                </el-table-column>
                <el-table-column  prop="price_basic" label="初始价">
                </el-table-column>
                <!-- <el-table-column  prop="qty" label="数量">
                </el-table-column> -->
                <!-- <el-table-column  prop="price_cost" label="成本" width="80">
                </el-table-column>
                <el-table-column  prop="price_sale" label="售价" width="80">
                </el-table-column> -->
                <el-table-column  prop="disc_rate" label="调价率%">
                </el-table-column>
                <el-table-column  prop="site_no" label="货位号">
                </el-table-column>
                <!-- <el-table-column  prop="disc_rate" label="供应商">
                </el-table-column> -->
            </el-table>
        </div>
        <el-drawer
            :visible.sync="drawer_chanpin"
            :modal='false'
            :show-close='false'
            title="产品"
            size="620">
            <div style="color:#888888;font-size:0.18rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">产品信息</div>
            <ul class="liebiao">
                <li class="dd1">
                    图片
                </li>
                <li class="dd2">
                    <div class="tupian_kuang" v-if="kan_list.img_url!=undefined&&kan_list.img_url.length!=0">
                        <img :src="kan_list.img_url" style="height: 1rem;width: 1rem;" alt=""> <i class="el-icon-error shanchu" v-if="kan_list.img_url.length!=0" @click="kan_list.img_url='';kan_list.b64='';kan_list.tu_type=''"></i>
                    </div>
                    <div class="tupian_kuang" v-if="kan_list.img_url==undefined||kan_list.img_url.length==0">
                        <input type="file" @change="getImgBasea()">
                        <img class="img_1" src="../../assets/increase.png" alt>
                    </div>
                </li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>产品编号
                </li>
                <li class="dd2"><input type="text" placeholder="请输入产品编号" style="background:#fff;color:#4C4A4D;" :disabled='!tianjiaa?true:false' v-model="kan_list.bianhao"></li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>产品
                </li>
                <li class="dd2"><input type="text" placeholder=请输入产品名称 v-model="kan_list.mingcheng" ></li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>仓库
                </li>
                <li class="dd2">
                    <el-tooltip class="item" effect="dark" :content="kan_list.cangku_name_no" placement="top-start">
                        <input type="text" @click="xuanze_cangku" placeholder=请选择仓库 v-model='kan_list.cangku_name_no' >
                    </el-tooltip>
                </li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span></span>类型
                </li>
                <li class="dd2">
                    <!-- <el-tree :data="data" show-checkbox default-expand-all node-key="id" ref="tree" highlight-current :props="defaultProps"></el-tree> -->
                    
                    <el-cascader v-if="editType == 'productAdd'" :options="chanpin_leixing_tiaozheng" :props="cascaderProps" v-model="cascaderValue" :show-all-levels="false"></el-cascader>

                    <input v-if="editType == 'productEdit'" type="text" disabled style="background:#f1f1f1;color:#666;" placeholder=请输入产品类型 v-model='kan_list.leixing' >
                    <!-- <p>{{chanpin_list[chanpin_list.length-1].name}}</p> -->
                </li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>规格
                </li>
                <li class="dd2"><input type="text" placeholder="请输入产品规格" v-model="kan_list.guige"></li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>型号
                </li>
                <li class="dd2"><input type="text" placeholder="请输入产品型号" v-model="kan_list.xinghao"></li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>单位
                </li>
                <li class="dd2"><input type="text" placeholder="请输入产品单位" v-model="kan_list.danwei"></li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>初始价
                </li>
                <li class="dd2"><input type="number" placeholder="请输入该产品单价" v-model="kan_list.danjia"></li>
            </ul>
            <!--  style="margin-bottom:height:0.97rem;" -->
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>调价率
                </li>
                <li class="dd2">
                    <input type="number" placeholder="请输入该产品调价率(1-100)" v-model="kan_list.zhekoulv"  @keyup="keyu2"  onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')">
                    <span class="baifen_hao">%</span>
                </li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span></span>货位号
                </li>
                <li class="dd2">
                    <input type="text" placeholder="请输入货位号" v-model="kan_list.huowei_hao">
                </li>
            </ul>
            <ul class="liebiao" style="opacity: 0;">
                <li class="dd1">

                </li>
                <li class="dd2">
                    <input type="number" placeholder="空白空白 这个不填"  >
                    <!-- <span class="baifen_hao">%</span> -->
                </li>
            </ul>
            <div class="xuan_foot">
                <div v-if="tianjiaa" style="color:#fff;background:#8d52e3" @click="box_sure_tianjia">添加</div>
                <div v-if="!tianjiaa" style="color:#fff;background:#8d52e3" @click="box_sure">确定</div>
                <!-- <div v-if="tianjiaa" style="color:#fff;background:#8d52e3" @click="box_jixu">继续添加</div> -->
                <!-- <div v-if="!tianjiaa" style="color:#888888;border:0.01rem solid #888888" @click="box_shanchu">删除</div> -->
                <div style="color:#888888;border:0.01rem solid #888888" @click="drawer_chanpin = false">取消</div>
            </div>
        </el-drawer>
        <el-drawer
            :visible.sync="dangqian_chan_pin"
            :modal='false'
            :show-close='false'
            title="产品"
            size="620">
            <div style="color:#888888;font-size:0.18rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">产品信息</div>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>产品类型
                </li>
                <li class="dd2"><input type="text" placeholder='请输入产品类型' v-model="chanpin_leixing" ></li>
            </ul>
            <div class="xuan_foot">
                <div v-if="leixing_chanpin==1" style="color:#fff;background:#8d52e3" @click="box_sure_tianjia_chanpinleixing">确定</div>
                <div v-if="leixing_chanpin==2" style="color:#fff;background:#8d52e3" @click="box_sure_bianji_chanpinleixing">确定</div>
                <div style="color:#888888;border:0.01rem solid #888888" @click="dangqian_chan_pin = false">取消</div>
            </div>
        </el-drawer>
        <el-dialog title="产品列表" :center="true" :show-close='false' :visible.sync="qiye_chanpin_leixing">
            <div class="tiao_bumen">
                <div class="mianbao_box">
                    <div v-for="(i,index) in chanpin_list" :key='index' @click="chanpin_dian_qi(i,index)">
                        <p :class="chanpin_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <ul>
                    <li v-for="(i,index) in qiye_chanpin_list_qi" :key="index" @click="dianji_bumen_qi(i)">
                        <div>
                            <input @click.stop ref="quana" type="checkbox" @click="tupian_dianji_qi(i,$event.path[0].checked,index)">
                            <p>{{i.cat_name}}</p>
                        </div>
                        <img class="img1" :src="i.child_flag=='0'?require('../../assets/chazi.png'):require('../../assets/chazi_ok.png')" alt="">
                    </li>
                </ul>
            </div>
            <div class="foote">
                <p class="queding" @click="tiaozheng_queding_qi">确定</p>
                <p class="quxiao" @click="tiaozheng_quxiao_qi">取消</p>
            </div>
        </el-dialog>
        <el-dialog title="产品列表" :center="true" :show-close='false' :visible.sync="qiye_bumen">
            <div class="tiao_bumena">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                    </div>
                    <div class="mianbaoxie">
                        <div v-for="(i,index) in chanpin_list" :key='index' @click="chanpin_dian_qi(i,index)">
                            <p :class="chanpin_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="quanxuan"><input type="checkbox" @click="dianji_quanxuan" ref='quan'><span>全选</span></div>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_chanpin_list_qi" :key="index" @click="dianji_bumen_qi(i)">
                                    <p style="margin-left:0.16rem;">{{i.cat_name}}</p>
                                    <img class="img1" :src="i.child_flag=='0'?require('../../assets/chazi.png'):require('../../assets/chazi_ok.png')" alt="">
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(i,index) in qiye_renyuan_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                    <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)">
                                    <p>{{i.prod_name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="yixuan">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                    <p>{{i.prod_name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="dianji_queding">确定</p>
                <p class="quxiao" @click="qiye_bumen=false">取消</p>
            </div>
        </el-dialog>
        <el-dialog title="调整产品类型" :center="true" :show-close='false' :visible.sync="tiaozheng_chanpin_leixing">
            <div class="tiao_bumen">
                <el-tree :data="chanpin_leixing_tiaozheng" node-key='id' ref="tiaozheng_chanpin_tree" style="background:none" default-expand-all show-checkbox check-strictly @check-change="handleClick" :props="defaultProps"></el-tree>
            </div>
            <div class="foote">
                <p class="queding" @click="sure_leixing">确定</p>
                <p class="quxiao" @click="tiaozheng_chanpin_leixing=false">取消</p>
            </div>
        </el-dialog>
        <zujianDuoxuan ref="cangku" @shuxing='chuanzhi'></zujianDuoxuan>
    </div>
</template>

<script>
import zujianDuoxuan from '../../components/zujian/Tree'
import {getItemByTree, getParentByKey} from '../../utils/dataTools'
import {query_ent_site_tree,have_exist_prod_code,query_cat_list_active_0,query_ent_prod_cat_list_tree,update_prod_cat_cat_batch,update_prod_cat_batch,update_prod_sort,update_category_sort,get_prod_list_like,update_prod_active_batch,update_prod_category_active_batch,delete_category_info,update_category_info,insert_category_info,query_prod_info_list,update_prod_info,insert_prod_info,delete_prod_info,query_category_info_list} from '../../api/api.js'
export default {
  name: "product",
  data() {
    return {
        tableData: [],
        multipleSelection: [],
        drawer_chanpin:false,
        kan_list:{
            leixing:'',
            chanpin:'',
            bianhao:'',
            guige:'',
            xinghao:'',
            danwei:'',
            // shuliang:'',
            danjia:"",
            // chengben:'',
            // shoujia:"",
            prod_id:'',
            zhekoulv:'',
            tu_type:'',
            img_url:'',
            b64:'',
            cangku_name_no:'',
            cangku_name:'',
            cangku_id:'',
            huowei_hao:''
        },
        tit_list:['启用','停用'],
        indea:'0',
        ent_id:'',
        chanpin_list:[
            {
                // name:'产品',
                // dept_id:'0',
            }
        ],
        qiye_chanpin_list:[],
        qiye_chanpin_list_qi:[],
        tianjiaa:true,
        dangqian_chan_pin:false,
        chanpin_leixing:'',
        // qiye_hcanpin_leixing:false,
        qiye_chanpin_leixing:false,
        inde:-1,
        indeb:-1,
        qiye_bumen:false,
        tiaozheng_leixing_id:'',
        dangqian_leixing:false,
        leixing_chanpin:'',
        jichuxinxi:'',
        act:'1',   //启用停用
        zicanpin:['是','否'],
        xin_shifou:'',
        chanpin_zhonglei_list:[],
        tingyong_leixing_list:[],
        text:'',
        qiye_renyuan_list:[],    //产品列表
        qiye_renyuan_list_xuan:[],
        cat_id:'',
        // 4.20修改
        tiaozheng_chanpin_leixing:false,
        xuanze_xiugai_leixing:'',
        defaultProps: {
            children: 'children',
            label: 'label'
        },
        chanpin_leixing_tiaozheng:[],
        sousuo_chanpin_name:'',
        // earl
        cascaderProps: {
            value: 'label',
            label: 'label',
            checkStrictly: true
        },
        cascaderValue: [],
        editType: ''
    };
  },
  components: { zujianDuoxuan },
  created(){
        if (sessionStorage.getItem("pc_mlbb_ent_info") != null&&sessionStorage.getItem("pc_mlbb_ent_info") != undefined&&sessionStorage.getItem("pc_mlbb_ent_info") != '') {
            this.ent_id =JSON.parse(sessionStorage.getItem("pc_mlbb_ent_info")).ent_id;
        }
        if (sessionStorage.getItem("pc_mlbb_jichuxinxi") != null&&sessionStorage.getItem("pc_mlbb_jichuxinxi") != undefined&&sessionStorage.getItem("pc_mlbb_jichuxinxi") != '') {
            this.jichuxinxi = JSON.parse(JSON.parse(sessionStorage.getItem("pc_mlbb_jichuxinxi")));
        }
        this.chanpin_list[0].name=JSON.parse(sessionStorage.getItem("pc_mlbb_ent_info")).ent_name;
  },
  mounted(){
      this.jichu()
  },
  watch:{
      chanpin_list(){
          if(this.chanpin_list.length!=1){
              this.dangqian_leixing=true
          }else{
              this.dangqian_leixing=false
          }
      },
      dangqian_chan_pin(){
          if(this.dangqian_chan_pin==false){
              this.chanpin_leixing=''
          }
      },
      qiye_renyuan_list_xuan(){
            if(this.qiye_renyuan_list_xuan.length==this.qiye_renyuan_list.length){
                this.$refs.quan.checked=true
            }else{
                this.$refs.quan.checked=false
            }
        },
        qiye_bumen(){
            if(!this.qiye_bumen){
                // console.log(this.$refs)
                this.text=''
            }
        },
        tiaozheng_chanpin_leixing(){
            if(!this.tiaozheng_chanpin_leixing){

            }else{
                this.dakai_chanpin_shu()
            }
        },
        drawer_chanpin(){
            if(!this.drawer_chanpin){
                this.kan_list.leixing=''
                this.kan_list.mingcheng=''
                this.kan_list.chanpin=''
                this.kan_list.bianhao=''
                this.kan_list.guige=''
                this.kan_list.xinghao=''
                this.kan_list.danwei=''
                this.kan_list.danjia=''
                this.kan_list.prod_id=''
                this.kan_list.zhekoulv=''
                this.kan_list.tu_type=''
                this.kan_list.img_url=''
                this.kan_list.b64=''
                this.kan_list.cangku_name_no=''
                this.kan_list.cangku_name=''
                this.kan_list.cangku_id=''
                this.kan_list.huowei_hao=''
                // earl
                this.cascaderValue = []
            }
        }
  },
  methods:{
      // 表头颜色
        biaotou(row){//根据报警级别显示颜色
            return 'color:#1A2533;'
        },
        // color:#4C4A4D;font-size:0.12rem;
        liebiao(row){
            return 'color:#4C4A4D;font-size:0.1rem;'
        },
    //   刚开始的基础信息
        jichu(){
            if(this.act==1){
                query_category_info_list({data:{
                    ent_id:this.ent_id,
                    user_id:this.jichuxinxi.user_id,
                    parent_id:this.act==1?this.chanpin_list[this.chanpin_list.length-1].dept_id:null,
                    active:this.act
                }}).then(res=>{
                    console.log(4444444,res)
                    if(res.data.code==10193){
                        let date=JSON.parse(res.data.body.data)
                        date.map(item=>{this.$set(item,'zhi',false)})
                        this.qiye_chanpin_list=date
                        console.log(this.qiye_chanpin_list)
                        this.renyuan_jiekou()
                        console.log(55555,date)
                    }else if(res.data.code==10194){
                    }
                })
            }else{
                query_cat_list_active_0({data:{
                    ent_id:this.$ent_id(),
                    user_id:this.$jichuxinxi().user_id,
                    active:this.act
                }}).then(res=>{
                    console.log(4444444,res)
                    if(res.data.code==10193){
                        let date=JSON.parse(res.data.body.data)
                        date.map(item=>{this.$set(item,'zhi',false)})
                        this.qiye_chanpin_list=date
                        this.renyuan_jiekou()
                        console.log(55555,date)
                    }else if(res.data.code==10194){
                    }
                })
            }
        },
        // 图片点击
        tupian_dianjia(index){
            this.indea=index+'';
                if(index==0){
                    this.act='1'
                    this.jichu()
                    // this.tian_no='添加'
                }else if(index==1){
                    this.act='0'
                    this.jichu()
                    // this.tian_no='停用'
                }
        },
        // 勾选产品]
        dianji_quanxian(i,e){
            console.log(i)
            if(e){
                this.chanpin_zhonglei_list.push(i)
            }else{
                for(let a=0;a<this.chanpin_zhonglei_list.length;a++){
                    if(i.cat_name==this.chanpin_zhonglei_list[a].cat_name){
                        this.chanpin_zhonglei_list.splice(a,1)
                    }
                }
            }
            console.log(this.chanpin_zhonglei_list)
        },
        // 批量修改产品类型启用停用状态接口
        ting_qiyong(){
            let list=[]
            this.qiye_chanpin_list.forEach(item=>{if(item.zhi){list.push(item)}})
            let cuowu=[]
            list.forEach(item=>{
                if(item.prod_num!=undefined&&item.prod_num!=0){
                    cuowu.push(item)
                }
            })
            console.log(list)

            if(cuowu.length==0&&list.length!=0){
                let ids=list.map(item=>item.cat_id).join(',')
                update_prod_category_active_batch({data:{
                    ent_id:this.ent_id,
                    user_id:this.jichuxinxi.user_id,
                    active:this.indea,
                    cat_ids:ids
                }}).then(res=>{
                    console.log(res)
                    if(res.data.code==10203){
                        if(this.indea==0){
                            this.$message({
                                message: '停用成功',
                                type: 'success'
                            });
                        }else if(this.indea==1){
                            this.$message({
                                message: '启用成功',
                                type: 'success'
                            });
                        }
                        this.jichu()
                        // for(let a=0;a<this.$refs.quana.length;a++){
                        //     this.$refs.quana[a].checked=false
                        // }
                    }else if(res.data.code==10204){

                    }
                })
            }else{
                this.$message({
                    message: '该产品类型下还有产品',
                    type: 'warning'
                });
            }
        },
        // 点击停用
        dianji_tingyong(){
            console.log(this.qiye_chanpin_list)
            let list=[]
            this.qiye_chanpin_list.map(item=>{
                if(item.zhi){
                    list.push('11111')
                }
            })
            if(list.length!=0){
                this.ting_qiyong()
            }else{
                this.$message({
                    message: '请选择要停用的产品种类',
                    type: 'warning'
                });
            }
        },
        // 点击启用
        dianji_qiyong(){
            console.log(this.qiye_chanpin_list)
            let list=[]
            this.qiye_chanpin_list.map(item=>{
                if(item.zhi){
                    list.push('11111')
                }
            })
            if(list.length!=0){
                this.ting_qiyong()
            }else{
                this.$message({
                    message: '请选择要启用的产品种类',
                    type: 'warning'
                });
            }
        },
        renyuan_jiekou(){
            query_prod_info_list({data:{
                ent_id:this.ent_id,
                user_id:this.jichuxinxi.user_id,
                cat_id:this.act==1?this.chanpin_list[this.chanpin_list.length-1].dept_id:null,
                active:this.act
            }}).then(res=>{
                console.log(res)
                if(res.data.code == 10139){
                    console.log(JSON.parse(res.data.body.data))
                    let date = JSON.parse(res.data.body.data)
                    console.log(date)
                    this.tableData = date
                    console.log(this.tableData)
                    this.tableData.map(item => {
                      if(item.prod_pic == '') {
                        return item.prod_pic = '-'
                      }
                    })
                }else if(res.data.code==10140){
                }
            })
        },
        renyuan(){
            this.bumen=true
        },
        jiegou(){
            this.bumen=false
        },
        // 产品批量停用的接口
        piliang_chanpin_tingyong(){
            let ids=[]
            for(let i=0;i<this.multipleSelection.length;i++){
                ids.push(this.multipleSelection[i].prod_id)
            }
            ids=ids.join(',')
            update_prod_active_batch({data:{
                ent_id:this.ent_id,
                user_id:this.jichuxinxi.user_id,
                prod_ids:ids,
                active:this.indea
            }}).then(res=>{
                console.log(res)
                if(res.data.code==10205){
                    if(this.indea==0){
                        this.$message({
                            message: '停用成功',
                            type: 'success'
                        });
                    }else if(this.indea==1){
                        this.$message({
                            message: '启用成功',
                            type: 'success'
                        });
                    }
                    this.jichu()
                }else if(res.data.code==10206){}
            })
        },
        // 点击停用产品种类
        tingyong_leixing(){
            query_category_info_list({data:{
                ent_id:this.ent_id,
                user_id:this.jichuxinxi.user_id,
                parent_id:this.chanpin_list[this.chanpin_list.length-1].dept_id,
                active:'1'
            }}).then(res=>{
                console.log(4444444,res)
                if(res.data.code==10193){
                    let date=JSON.parse(res.data.body.data)
                    date.map(item=>{this.$set(item,'zhi',false)})
                    this.qiye_chanpin_list_qi=date
                    this.renyuan_jiekou()
                    console.log(55555,date)
                }else if(res.data.code==10194){
                }
            })
            this.qiye_chanpin_leixing=true
        },
        // 产品批量停用
        piliang_tingyong(){
            if(this.multipleSelection.length!=0){
                this.piliang_chanpin_tingyong()
            }else{
                this.$message({
                    message: '请选择要停用的产品种类',
                    type: 'warning'
                });
            }
        },
        tingyong_chanpin_btn(){
            this.qiye_bumen=true
            query_category_info_list({data:{
                ent_id:this.ent_id,
                user_id:this.jichuxinxi.user_id,
                parent_id:this.chanpin_list[this.chanpin_list.length-1].dept_id,
                active:'1'
            }}).then(res=>{
                console.log(4444444,res)
                if(res.data.code==10193){
                    date.map(item=>{this.$set(item,'zhi',false)})
                    let date=JSON.parse(res.data.body.data)
                    this.qiye_chanpin_list_qi=date
                    query_prod_info_list({data:{
                        ent_id:this.ent_id,
                        user_id:this.jichuxinxi.user_id,
                        cat_id:this.chanpin_list[this.chanpin_list.length-1].dept_id,
                        active:'1'
                    }}).then(ras=>{
                        if(ras.data.code==10139){
                            console.log(JSON.parse(ras.data.body.data))
                            let date=JSON.parse(ras.data.body.data)
                            this.qiye_renyuan_list=date
                        }else if(ras.data.code==10140){
                        }
                    })
                    console.log(55555,date)
                }else if(res.data.code==10194){
                }
            })
        },
        // 产品批量启用
        piliang_qiyong(){
            if(this.multipleSelection.length!=0){
                this.piliang_chanpin_tingyong()
            }else{
                this.$message({
                    message: '请选择要启用的产品种类',
                    type: 'warning'
                });
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            console.log(44444,val)
        },
        hangdian(val){
            console.log(val,'单机了哪个')
            this.editType = 'productEdit'
            // this.dakai_chanpin_shu('productEdit', val.cat_name, 'label')
            
            let data=this.kan_list
            data.leixing=val.cat_name
            data.mingcheng=val.prod_name
            data.bianhao=val.prod_code
            data.prod_id=val.prod_id
            data.guige=val.spec
            data.xinghao=val.type
            data.danwei=val.unit
            // data.shuliang=val.qty
            data.danjia=val.price_basic
            // data.chengben=val.price_cost
            // data.shoujia=val.price_sale
            data.zhekoulv=val.disc_rate
            // let tupian=val.prod_pic!=undefined&&val.prod_pic.length!=0?this.$func.tupian_zhuanhua(val.prod_pic):{}

            function getBase64Image(img) {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d"); //获取绘图上下文环境
                ctx.drawImage(img, 0, 0, img.width, img.height); //在canvas中画图
                var dataURL = canvas.toDataURL("image/png"); //可选其他值 image/jpeg
                return dataURL;
            }
            function main(src, cb) {
                var image = new Image();
                image.src = src + '?v=' + Math.random(); // 处理缓存
                image.crossOrigin = "*";                 // 支持跨域图片
                image.onload = function() {
                    var base64 = getBase64Image(image);
                    cb && cb(base64);
                }
            }

            data.tu_type=val.prod_pic!=undefined&&val.prod_pic.length!=0?val.prod_pic.substring(val.prod_pic.lastIndexOf('.')):''
            // data.tu_type=tupian.lei!=undefined&&tupian.lei.length!=0?tupian.lei:null
            data.img_url=val.prod_pic!=undefined&&val.prod_pic.length!=0?val.prod_pic:''
            // data.b64=tupian.lei!=undefined&&tupian.lei.length!=0?tupian.xin:null
            data.huowei_hao=val.site_no!=undefined&&val.site_no.length!=0?val.site_no:''
            data.cangku_name_no=val.site_name!=undefined&&val.site_name.length!=0?val.site_name:''
            data.cangku_name=val.site_name!=undefined&&val.site_name.length!=0?val.site_name:''
            data.cangku_id=val.site_id!=undefined&&val.site_id.length!=0?val.site_id:''
            this.tiaozheng_leixing_id=val.cat_id


            this.drawer_chanpin=true;

            console.log('cascaderValue', this.cascaderValue)
            
            this.tianjiaa=false
              if(val.prod_pic!=undefined&&val.prod_pic.length!=0 && val.img_url === '' ){
                main(val.prod_pic, function(base64) {
                    console.log(base64);
                    data.b64=base64
                })
            }else{
                data.b64=data.img_url
            }

        },
        //   保存
        box_sure(){
            let date=this.kan_list
            // &&date.shuliang!=''
            if(date.cangku_id.length!=0&&date.zhekoulv!=undefined&&date.zhekoulv!=''&&date.bianhao!=''&&date.guige!=''&&date.xinghao!=''&&date.danwei!=''&&date.danjia!=''){
                  let param ={
                            prod_id:date.prod_id,
                            prod_name:date.mingcheng,
                            prod_code:date.bianhao,
                            cat_id:this.chanpin_list[this.chanpin_list.length-1].dept_id,
                            spec:date.guige,
                            // qty:date.shuliang,
                            unit:date.danwei,
                            type:date.xinghao,
                            price_basic:date.danjia+'',
                            // price_cost:date.chengben+'',
                            // price_sale:date.shoujia+'',
                            disc_rate:date.zhekoulv+'',
                            site_no:date.huowei_hao.length!=0?date.huowei_hao+'':null,
                            cat_name:this.chanpin_list[this.chanpin_list.length-1].name,
                            ent_id:this.ent_id,
                            user_id:this.jichuxinxi.user_id,
                            prod_pic: date.b64.length == 0 ? null:date.b64,//图片b64
                            prod_pic_type:date.tu_type.length!=0?date.tu_type:null,//图片类型
                            site_id:date.cangku_id,//仓库id
                            site_name:date.cangku_name,//仓库名称
                        }

                        update_prod_info({data:param}).then(res=>{
                           // console.log(JSON.parse(res.config.data).data.prod_pic,'这是返回的res')
                            if(res.data.code==10137){
                                this.$message({
                                    message: '修改成功',
                                    type: 'success'
                                });
                                this.jichu()
                                this.drawer_chanpin=false;
                                this.tiaozheng_leixing_id=''
                            }else if(res.data.code==10138){
                                this.$message({
                                    message: '修改失败',
                                    type: 'error'
                                });
                            }
                        })
                // //     }
                // })
            }else{
                this.$message({
                    message: '请填写完整的信息',
                    type: 'warning'
                });
            }
        },
        // 产品列表点击
        chanpin_dian(i,index){

            if(this.chanpin_list.length-1!=index){
                query_category_info_list({data:{
                    ent_id:this.ent_id,
                    user_id:this.jichuxinxi.user_id,
                    parent_id:i.dept_id,
                    active:this.act
                }}).then(ras=>{
                    if(ras.data.code==10193){
                        let listdata=JSON.parse(ras.data.body.data)

                        listdata.map(item=>{this.$set(item,'zhi',false)})
                        this.qiye_chanpin_list=listdata
                        this.chanpin_list.splice(index+1,this.chanpin_list.length)
                        this.renyuan_jiekou()
                    }else if(ras.data.code==10194){
                    }
                })
            }
        },
        // 产品列表点击
        chanpin_dian_qi(i,index){

            if(this.chanpin_list.length-1!=index){
                query_category_info_list({data:{
                    ent_id:this.ent_id,
                    user_id:this.jichuxinxi.user_id,
                    parent_id:i.dept_id,
                    active:'1'
                }}).then(ras=>{
                    if(ras.data.code==10193){
                        let listdata=JSON.parse(ras.data.body.data)

                        listdata.map(item=>{this.$set(item,'zhi',false)})
                        this.qiye_chanpin_list_qi=listdata
                        this.chanpin_list.splice(index+1,this.chanpin_list.length)
                        this.renyuan_jiekou()
                        query_prod_info_list({data:{
                            ent_id:this.ent_id,
                            user_id:this.jichuxinxi.user_id,
                            cat_id:this.chanpin_list[this.chanpin_list.length-1].dept_id,
                            active:'1'
                        }}).then(ras=>{
                            if(ras.data.code==10139){

                                let date=JSON.parse(ras.data.body.data)
                                this.qiye_renyuan_list=date
                            }else if(ras.data.code==10140){
                            }
                        })
                    }else if(ras.data.code==10194){
                    }
                })
            }
        },
        // 点击产品
        dianji_chanpin(i){
            if(this.indea==0){

                this.chanpin_list.push({
                    name:i.cat_name,
                    dept_id:i.cat_id,
                })
                

                // 为添加产品默认选中类型准备 S
                let arr = []
                if(this.chanpin_list.length > 1) {
                    this.chanpin_list.map((item, index)=>{
                        arr.push(item.name)
                    })
                }
                this.cascaderValue = arr.slice(1, arr.length)
                // 为添加产品默认选中类型准备 E

                query_category_info_list({data:{
                    ent_id:this.ent_id,
                    user_id:this.jichuxinxi.user_id,
                    parent_id:i.cat_id,
                    active:this.act
                }}).then(res=>{

                    if(res.data.code==10193){
                        let date=JSON.parse(res.data.body.data)
                        date.map(item=>{this.$set(item,'zhi',false)})
                        this.qiye_chanpin_list=date

                        this.renyuan_jiekou()
                    }else if(res.data.code==10194){
                    }
                })
            }
        },
        // 添加产品
        tianjia_chanpin_btn(){
            this.editType = 'productAdd'
            this.drawer_chanpin=true;
            this.chufa()
            this.dakai_chanpin_shu()
        },
        // 点击添加产品触发
        chufa(){
            this.tianjiaa=true
            let data=this.kan_list
            data.leixing=''
            data.mingcheng=''
            data.bianhao=''
            data.prod_id=''
            data.guige=''
            data.xinghao=''
            data.danwei=''
            data.danjia=''
            data.zhekoulv=''
            data.huowei_hao=''
        },
        // cascaderChange(e) {
        //     console.log('eeee', e, this.chanpin_list)
        //     this.chanpin_list[this.chanpin_list.length-1].name = e[e.length - 1]
        //     let obj = getItemByTree(this.chanpin_list, item, 'name')
        // },
        // 添加产品里的确定
        box_sure_tianjia(){
            let date=this.kan_list
            console.log('this.chanpin_list', this.chanpin_list)
            // &&date.shuliang==''
            if(date.cangku_id.length!=0&&date.zhekoulv!=''&&date.mingcheng!=''&&date.bianhao!=''&&date.guige!=''&&date.xinghao!=''&&date.danwei!=''&&date.danjia!=''){

                
                // earl s
                // 此处主要为了
                let arr = []
                if(this.cascaderValue && this.cascaderValue.length > 0) {
                    if(this.chanpin_list && this.chanpin_list.length > 1) {
                        this.cascaderValue.map(item=>{
                            let obj = getItemByTree(this.chanpin_list, item, 'name')
                            arr.push(obj)
                        })
                    } else {
                        this.cascaderValue.map(item=>{
                            let obj = getItemByTree(this.chanpin_leixing_tiaozheng, item, 'label')
                            obj['dept_id'] = obj.id
                            obj['name'] = obj.label
                            arr.push(obj)
                        })
                    }
                }
                // // earl e

                have_exist_prod_code({data:{
                    ent_id:this.$ent_id(),
                    prod_code:date.bianhao
                }}).then(you=>{

                    if(you.data.code==600){
                        this.$message({
                            message: '添加失败',
                            type: 'error'
                        });
                    }else if(you.data.code==700){
                        insert_prod_info({data:{
                            prod_name:date.mingcheng,
                            prod_code:date.bianhao,
                            cat_id: arr[arr.length - 1].dept_id, // this.chanpin_list[this.chanpin_list.length-1].dept_id,
                            spec:date.guige,
                            // qty:date.shuliang,
                            unit:date.danwei,
                            type:date.xinghao,
                            price_basic:date.danjia,
                            // price_cost:date.chengben,
                            // price_sale:date.shoujia,
                            disc_rate:date.zhekoulv+'',
                            site_no:date.huowei_hao.length!=0?date.huowei_hao+'':null,
                            cat_name: arr[arr.length - 1].name, // this.chanpin_list[this.chanpin_list.length-1].name,
                            ent_id:this.ent_id,
                            user_id:this.jichuxinxi.user_id,

                            prod_pic:date.b64.length!=0?date.b64:null,//图片b64
                            prod_pic_type:date.tu_type.length!=0?date.tu_type:null,//图片类型
                            site_id:date.cangku_id,//仓库id
                            site_name:date.cangku_name,//仓库名称
                        }}).then(res=>{

                            if(res.data.code==10157){
                                this.$message({
                                    message: '添加成功',
                                    type: 'success'
                                });
                                this.jichu()
                                // this.tableData.forEach(item => {
                                //     if(item.prod_pic == null) {
                                //       item.prod_pic = '-' 
                                //     }
                                // })
                                this.drawer_chanpin=false;
                                this.tiaozheng_leixing_id=''
                            }else if(res.data.code==10158){
                                this.$message({
                                    message: '添加失败',
                                    type: 'error'
                                });
                            }
                        })
                    }
                })
            }else{
                this.$message({
                    message: '请填写完整的信息',
                    type: 'warning'
                });
            }
        },
        // 批量删除
        piliang_shanchu(){
            if(this.multipleSelection.length>0){
                let id=[]
                for(let i=0;i<this.multipleSelection.length;i++){
                    id.push(this.multipleSelection[i].prod_id)
                }
                id=id.join(',')
                delete_prod_info({data:{
                    prod_ids:id,
                    ent_id:this.ent_id,
                    user_id:this.jichuxinxi.user_id,
                }}).then(res=>{

                    if(res.data.code==10141){
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        this.jichu();
                    }else if(res.data.code==10142){
                        this.$message({
                            message: '删除失败',
                            type: 'error'
                        });
                    }else if(res.data.code==11000){
                        this.$message({
                            message: '该产品已有目标',
                            type: 'error'
                        });
                    }
                })
            }else{
                this.$message({
                    message: '至少选中一个产品进行删除',
                    type: 'warning'
                });
            }
        },
        // 编辑产品信息页的删除
        box_shanchu(){

            delete_prod_info({data:{
                prod_ids:this.kan_list.prod_id,
                ent_id:this.ent_id,
                user_id:this.jichuxinxi.user_id,
            }}).then(res=>{

                if(res.data.code==10141){
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.jichu();
                    this.drawer_chanpin=false
                }else if(res.data.code==10142){
                    this.$message({
                        message: '删除失败',
                        type: 'error'
                    });
                }else if(res.data.code==11000){
                    this.$message({
                        message: '该产品已有目标',
                        type: 'error'
                    });
                }
            })
        },
        // 添加产品类型
        tianjia_leixing(){
            this.dangqian_chan_pin=true
            this.leixing_chanpin=1
            this.xin_shifou=''
        },
        // 添加产品类型确定
        box_sure_tianjia_chanpinleixing(){
            if(this.chanpin_leixing!=''){
                insert_category_info({data:{
                    cat_name:this.chanpin_leixing,
                    ent_id:this.ent_id,
                    user_id:this.jichuxinxi.user_id,
                    parent_id:this.chanpin_list[this.chanpin_list.length-1].dept_id,
                    child_flag:'1'
                }}).then(res=>{
                    console.log(res)
                    if(res.data.code==10195){
                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        });
                        this.jichu()
                        this.dangqian_chan_pin=false
                    }else if(res.data.code==10196){
                        this.$message({
                            message: '添加失败',
                            type: 'error'
                        });
                    }else if(res.data.code==11200){
                        this.$message({
                            message: '已有产品类型名称',
                            type: 'warning'
                        });
                    }
                })
            }else{
                this.$message({
                    message: '请填写完整信息',
                    type: 'warning'
                });
            }
        },
        // 点击部门获取下面子部门
        dianji_bumen(i){

            if(i.child_flag==1){
                query_category_info_list({data:{
                    ent_id:this.ent_id,
                    user_id:this.jichuxinxi.user_id,
                    parent_id:i.cat_id,
                    active:this.act
                }}).then(res=>{
                    if(res.data.code==10193){
                        let date=JSON.parse(res.data.body.data)
                        date.map(item=>{this.$set(item,'zhi',false)})

                        this.qiye_chanpin_list=date
                        this.chanpin_list.push({name:i.cat_name,dept_id:i.cat_id})
                    }else if(res.data.code==10194){
                    }
                })
            }
        },
        // 点击部门获取下面子部门
        dianji_bumen_qi(i){

            if(i.child_flag==1){
                query_category_info_list({data:{
                    ent_id:this.ent_id,
                    user_id:this.jichuxinxi.user_id,
                    parent_id:i.cat_id,
                    active:'1'
                }}).then(res=>{
                    if(res.data.code==10193){
                        let date=JSON.parse(res.data.body.data)

                        date.map(item=>{this.$set(item,'zhi',false)})
                        this.qiye_chanpin_list_qi=date
                        this.chanpin_list.push({name:i.cat_name,dept_id:i.cat_id})
                        query_prod_info_list({data:{
                            ent_id:this.ent_id,
                            user_id:this.jichuxinxi.user_id,
                            cat_id:this.chanpin_list[this.chanpin_list.length-1].dept_id,
                            active:'1'
                        }}).then(ras=>{
                            if(ras.data.code==10139){

                                let date=JSON.parse(ras.data.body.data)
                                this.qiye_renyuan_list=date
                            }else if(ras.data.code==10140){
                            }
                        })
                    }else if(res.data.code==10194){
                    }
                })
            }
        },
        tupian_dianji(index){
            this.inde=index
        },
        tupian_dianji_qi(i,e){
            if(e){
                this.tingyong_leixing_list.push(i)
            }else{
                for(let a=0;a<this.tingyong_leixing_list.length;a++){
                    if(i.cat_name==this.tingyong_leixing_list[a].cat_name){
                        this.tingyong_leixing_list.splice(a,1)
                    }
                }
            }
        },
        // // 调整确定及取消
        // tiaozheng_queding(){
        //     console.log(this.inde)
        //     if(this.inde!=-1){
        //         console.log(4444,this.qiye_chanpin_list[this.inde])
        //         this.tiaozheng_leixing_id=this.qiye_chanpin_list[this.inde].cat_id
        //         this.kan_list.leixing=this.qiye_chanpin_list[this.inde].cat_name
        //         this.qiye_hcanpin_leixing=false
        //         this.inde=-1
        //     }else{
        //         this.$message({
        //             message: '请选择您要调整的部门',
        //             type: 'warning'
        //         });
        //     }
        // },
        // 调整确定及取消
        tiaozheng_queding_qi(){
            if(this.tingyong_leixing_list.length!=0){
                let ids=[]
                for(let i=0;i<this.tingyong_leixing_list.length;i++){
                    ids.push(this.tingyong_leixing_list[i].cat_id)
                }
                ids=ids.join(',')

                update_prod_category_active_batch({data:{
                    ent_id:this.ent_id,
                    user_id:this.jichuxinxi.user_id,
                    active:'0',
                    cat_ids:ids
                }}).then(res=>{

                    if(res.data.code==10203){
                        if(this.indea==1){
                            this.$message({
                                message: '停用成功',
                                type: 'success'
                            });
                        }
                        this.jichu()
                        ids=[]
                        this.qiye_chanpin_leixing=false
                        this.tingyong_leixing_list=[]
                    }else if(res.data.code==10204){

                    }
                })
            }else{
                this.$message({
                    message: '请选择要停用的产品种类',
                    type: 'warning'
                });
            }
        },
        // tiaozheng_quxiao(){
        //     this.qiye_hcanpin_leixing=false
        // },
        tiaozheng_quxiao_qi(){
            this.qiye_chanpin_leixing=false
        },
        dianji_bianji(i){
            this.leixing_chanpin=2

            this.chanpin_leixing=i.name
            this.cat_id=i.dept_id
            this.dangqian_chan_pin=true

            // this.xin_shifou=
            // if(i.child_flag==0){
            //     this.xin_shifou='否'
            // }else if(i.child_flag==1){
            //     this.xin_shifou='是'
            // }
        },
        // 编辑产品
        box_sure_bianji_chanpinleixing(){
            if(this.chanpin_leixing!=''){
                update_category_info({data:{
                    ent_id:this.ent_id,
                    user_id:this.jichuxinxi.user_id,
                    cat_id:this.cat_id,
                    cat_name:this.chanpin_leixing
                }}).then(res=>{

                    if(res.data.code==10199){
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        // this.chanpin_list[this.chanpin_list.length-1].name=this.chanpin_leixing
                        this.dangqian_chan_pin=false
                        this.chanpin_list[this.chanpin_list.length-1].name=this.chanpin_leixing
                        this.jichu()
                        this.cat_id=''
                    }else if(res.data.code==10200){
                        this.$message({
                            message: '修改失败',
                            type: 'error'
                        });
                    }
                })
            }
        },
        // 删除
        box_no_tianjia_chanpinleixing(){
            delete_category_info({data:{
                ent_id:this.ent_id,
                user_id:this.jichuxinxi.user_id,
                cat_ids:this.chanpin_list[this.chanpin_list.length-1].dept_id
            }}).then(res=>{

                if(res.data.code==10197){
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.dangqian_chan_pin=false
                    this.chanpin_list.splice(this.chanpin_list.length-1,1)
                    this.jichu()
                }else if(res.data.code==10198){
                    this.$message({
                        message: '删除失败',
                        type: 'error'
                    });
                }else if(res.data.code==10800){
                    this.$message({
                        message: '该产品类型下存在产品',
                        type: 'warning'
                    });
                }else if(res.data.code==11000){
                    this.$message({
                        message: '该产品类型下产品已有目标',
                        type: 'warning'
                    });
                }
            })
        },
        // 进入导入页
        jinru_daoru(){
            // this.$router.push('/importprod')
            sessionStorage.setItem("pc_mlbb_piliangdaoru", '产品');
            this.$router.push('/imports')
        },
        // 搜索产品
        keyu(){
            if(this.text.length==0){
                this.text=null
            }
            get_prod_list_like({data:{
                ent_id:this.ent_id,
                user_id:this.jichuxinxi.user_id,
                prod_name:this.text,
                active:'1'
            }}).then(res=>{

                if(res.data.code==10031){

                    let date=JSON.parse(res.data.body.data)
                    this.qiye_renyuan_list=date
                }else if(res.data.code==10032){
                }
            })
        },
        // 点击全选
        dianji_quanxuan(e){

            if(e.path[0].checked){
                for(let i=0;i<this.$refs.have.length;i++){
                    this.$refs.have[i].checked=true
                }
                this.qiye_renyuan_list_xuan=[]
                for(let i=0;i<this.qiye_renyuan_list.length;i++){
                    this.qiye_renyuan_list_xuan.push(this.qiye_renyuan_list[i])
                }

            }else{
                for(let i=0;i<this.$refs.have.length;i++){
                    this.$refs.have[i].checked=false
                }
                this.qiye_renyuan_list_xuan=[]
            }
        },
        // 选择停用————单选
        dianji_xuanren(i,e,index){

            if(e.path[0].checked==true){
                this.qiye_renyuan_list_xuan.push(i)
            }else{

                for(let i=0;i<this.qiye_renyuan_list_xuan.length;i++){
                    if(this.qiye_renyuan_list[index].prod_name==this.qiye_renyuan_list_xuan[i].prod_name){
                        this.qiye_renyuan_list_xuan.splice(i,1)
                    }
                }
            }
        },
        // 选着停用————确定
        dianji_queding(){
            if(this.qiye_renyuan_list_xuan.length!=0){
                let list=[]
                for(let i=0;i<this.qiye_renyuan_list_xuan.length;i++){
                    list.push(this.qiye_renyuan_list_xuan[i].prod_id)
                }
                list=list.join(',')

                update_prod_active_batch({data:{
                    ent_id:this.ent_id,
                    user_id:this.jichuxinxi.user_id,
                    prod_ids:list,
                    active:'0'
                }}).then(res=>{

                    if(res.data.code==10205){
                        if(this.indea==0){
                            this.$message({
                                message: '停用成功',
                                type: 'success'
                            });
                        }else if(this.indea==1){
                            this.$message({
                                message: '启用成功',
                                type: 'success'
                            });
                        }
                        this.jichu()
                        this.qiye_bumen=false
                    }else if(res.data.code==10206){}
                })
            }else{
                this.$message({
                    message: '请选择产品',
                    type: 'warning'
                });
            }
        },
        // 进入导出
        jinru_daochu(){
            sessionStorage.setItem("pc_pc_pc_daochu", '产品');
            this.$router.push('/exportb')
        },
        // 监听正整数
        keyu2(){
            if(this.kan_list.zhekoulv<=0){
                this.kan_list.zhekoulv=1
            }else if(this.kan_list.zhekoulv>100){
                this.kan_list.zhekoulv=100
            }
        },
        shangyi(i,index){

            // let qiye_list=this.qiye_bumen_list.map(item=>item)
            if(index!=0){
                this.qiye_chanpin_list.splice(index,1)
                this.qiye_chanpin_list.splice(Number(index)-1,0,i)
                this.tiaozheng_shunxu(this.qiye_chanpin_list)
            }
        },
        tiaozheng_shunxu(i){
            let list=[]
            for(let a=0;a<i.length;a++){
                list.push({
                    cat_id:i[a].cat_id,
                    sort:a+1
                })
            }
            update_category_sort({data:{
                ent_id:this.$ent_id(),
                list:list
            }}).then(res=>{
                if(res.data.code==200){
                    this.$message({
                        message: '调整成功',
                        type: 'success'
                    });
                }else if(res.data.code==500){}
            })
        },
        xiayi(i,index){
            if(index!=Number(this.qiye_chanpin_list.length)-1){
                this.qiye_chanpin_list.splice(index,1)
                this.qiye_chanpin_list.splice(index+1,0,i)
                this.tiaozheng_shunxu(this.qiye_chanpin_list)
            }
        },
        // update_prod_sort
        chanpin_shangyi(){
            if(this.multipleSelection.length == 1){
                let index = this.tableData.findIndex(item => item.prod_name == this.multipleSelection[0].prod_name)
                console.log(index)
                if(index == 0) {
                    this.$message.warning('此物品不能上移')
                } else {
                    this.tableData[index] = this.tableData.splice(index - 1, 1, this.tableData[index])[0]
                    this.tiaozheng_shunxu_chanpin(this.tableData)
                }
            }else{
                this.$message({
                    message: '请选择一个进行下移',
                    type: 'warning'
                })
            }
        },
        tiaozheng_shunxu_chanpin(i){
            let list=[]
            for(let a=0;a<i.length;a++){
                list.push({
                    prod_id:i[a].prod_id,
                    sort:a+1
                })
            }
            update_prod_sort({data:{
                ent_id:this.$ent_id(),
                list:list
            }}).then(res=>{
                if(res.data.code==200){
                    this.$message({
                        message: '调整成功',
                        type: 'success'
                    });
                }else if(res.data.code==500){}
            })
        },
        chanpin_xiayi(){
            if(this.multipleSelection.length == 1){
                let index = this.tableData.findIndex(item => item.prod_name == this.multipleSelection[0].prod_name)
                console.log(index)
                if(index == this.tableData.length - 1) {
                    this.$message.warning('此产品不能下移')
                } else {
                    this.tableData[index] = this.tableData.splice(index + 1, 1, this.tableData[index])[0]
                    this.tiaozheng_shunxu_chanpin(this.tableData)
                }
            }else{
                this.$message({
                    message: '请选择一个进行下移',
                    type: 'warning'
                })
            }
        },
        //调整产品大类
        tiaozheng_dalei(text){
            this.xuanze_xiugai_leixing=text
            if(text=='大类'){
                let arr=[]
                for(let i=0;i<this.qiye_chanpin_list.length;i++){
                    if(this.qiye_chanpin_list[i].zhi){
                        arr.push('1111')
                    }
                }
                if(arr.length!=0){
                    this.tiaozheng_chanpin_leixing=true
                }else{
                    this.$message({
                        message: '还未选择产品类型',
                        type: 'warning'
                    });
                }
            }else if(text=='小类'){
                if(this.multipleSelection.length!=0){
                    this.tiaozheng_chanpin_leixing=true
                }else{
                    this.$message({
                        message: '还未选择产品',
                        type: 'warning'
                    });
                }
            }
        },
        // 修改产品类型
        sure_leixing(){
            if(this.xuanze_xiugai_leixing=='大类'){
                let arr=[]
                for(let i=0;i<this.qiye_chanpin_list.length;i++){
                    if(this.qiye_chanpin_list[i].zhi){
                        arr.push(this.qiye_chanpin_list[i])
                    }
                }
                update_prod_cat_cat_batch({data:{
                    ent_id:this.$ent_id(),
                    cat_id:this.$refs.tiaozheng_chanpin_tree.getCheckedNodes().length!=0?this.$refs.tiaozheng_chanpin_tree.getCheckedNodes()[0].id:'0',
                    cat_ids:arr.map(item=>item.cat_id).join(',')
                }}).then(res=>{

                    if(res.data.code==200){
                        this.jichu()
                        this.tiaozheng_chanpin_leixing=false
                        this.$message({
                            message: '调整成功',
                            type: 'success'
                        });
                    }else if(res.data.code==500){}
                })
            }else if(this.xuanze_xiugai_leixing=='小类'){
                update_prod_cat_batch({data:{
                    ent_id:this.$ent_id(),
                    cat_id:this.$refs.tiaozheng_chanpin_tree.getCheckedNodes().length!=0?this.$refs.tiaozheng_chanpin_tree.getCheckedNodes()[0].id:'0',
                    cat_name:this.$refs.tiaozheng_chanpin_tree.getCheckedNodes().length!=0?this.$refs.tiaozheng_chanpin_tree.getCheckedNodes()[0].label:'0',
                    prod_ids:this.multipleSelection.map(item=>item.prod_id).join(',')
                }}).then(res=>{

                    if(res.data.code==200){
                        this.jichu()
                        this.tiaozheng_chanpin_leixing=false
                        this.$message({
                            message: '调整成功',
                            type: 'success'
                        });
                    }else if(res.data.code==500){}
                })
            }
        },
        // 打开产品树
        dakai_chanpin_shu(type, findValue, findKey){
            query_ent_prod_cat_list_tree({data:{
                ent_id:this.$ent_id()
            }}).then(res=>{

                if(res.data.code==200){
                    let date=JSON.parse(res.data.body.data)
                    let _this=this

                    // this.xuanze_xiugai_leixing=='大类'
                    function xunhuan(data){
                        if(data){
                            let arr=[]
                            for(let i=0;i<_this.qiye_chanpin_list.length;i++){
                                if(_this.qiye_chanpin_list[i].zhi){
                                    arr.push(_this.qiye_chanpin_list[i])
                                }
                            }
                            if(_this.chanpin_list[_this.chanpin_list.length-1].dept_id==0){
                                arr.forEach(item=>{
                                    for(let a=0;a<data.length;a++){
                                        if(item.cat_id==data[a].id){
                                            data.splice(a,1)
                                            a=a-1
                                        }
                                    }
                                })
                            }else{
                                data.forEach(item=>{
                                    if(_this.chanpin_list[_this.chanpin_list.length-1].dept_id==item.id){
                                        arr.forEach(ite=>{
                                            for(let a=0;a<item.children.length;a++){
                                                if(ite.cat_id==item.children[a].id){
                                                    item.children.splice(a,1)
                                                    a=a-1
                                                }
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    }
                    if(this.xuanze_xiugai_leixing=='大类'){
                        xunhuan(date)
                    }
                    console.log('分类树', date)
                    let delChildren = function(arr) {
                        arr.map(item=>{
                            if(item.children && item.children.length == 0) {
                                delete item.children
                            } else {
                                delChildren(item.children)
                            }
                        })
                    }
                    delChildren(date)
                    this.chanpin_leixing_tiaozheng=date
                    // if(type == 'productEdit') {
                    //     this.cascaderValue = []
                    //     this.getParentByKey(date, findValue, findKey)
                    //     // this.cascaderValue = getParentByKey(date, findValue, findKey)
                    //     // console.log('this.cascaderValue', getParentByKey(date, findValue, findKey))
                    // }
                }else if(res.data.code==500){}
            })
        },
        // getParentByKey(tree, value, key) {
        //     tree.forEach(item=>{
        //         if(item[key] == value) {
        //         this.cascaderValue.push(value)
        //         return
        //         } else {
        //         if(item.children && item.children.length) {
        //             if(item.children.some(row=> row[key] == value)) {
        //             this.cascaderValue.push(item[key])
        //             console.log('item[key]', item[key])
        //             this.getParentByKey(item.children, value, key)
        //             }
        //         } else {
        //             return
        //         }
        //         }
        //     })
        // },
        handleClick(data,checked, node){
            if(checked){
                this.$refs.tiaozheng_chanpin_tree.setCheckedNodes([data]);
            }
        },
        chaxun_name(){
            if(this.sousuo_chanpin_name.length==0){
                this.renyuan_jiekou()
            }else{
                get_prod_list_like({data:{
                    ent_id:this.$ent_id(),
                    user_id:this.$jichuxinxi().user_id,
                    prod_name:this.sousuo_chanpin_name.length!=0?this.sousuo_chanpin_name:null,
                    active:'1'
                }}).then(res=>{

                    if(res.data.code==10031){

                        let date=JSON.parse(res.data.body.data)
                        this.tableData=date
                    }else if(res.data.code==10032){
                    }
                })
            }

        },
        // 删除产品大类
        shanchu_chanpin(){
            let list=[]
            this.qiye_chanpin_list.forEach(item=>{
                if(item.zhi){
                    list.push(item)
                }
            })

            if(list.length!=0){
                delete_category_info({data:{
                    ent_id:this.$ent_id(),
                    cat_ids:list.map(it=>it.cat_id).join(','),
                    user_id:this.$jichuxinxi().user_id
                }}).then(res=>{

                    if(res.data.code==10197){
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        this.jichu()
                    }else if(res.data.code==10198){}else if(res.data.code==10800){
                        this.$message({
                            message: '该产品类型下存在产品',
                            type: 'warning'
                        });
                    }else if(res.data.code==11000){
                        this.$message({
                            message: '该产品类型下产品已有目标',
                            type: 'warning'
                        });
                    }
                })
            }else{
                this.$message({
                    message: '请选择产品类型进行删除',
                    type: 'warning'
                });
            }
        },
        //获取图片base64实现预览
        getImgBasea() {
            var event = event || window.event;
            var file = event.target.files[0];
            var reader = new FileReader();
            let _this=this
            // i.lei = file.name.substring(file.name.lastIndexOf("."), file.name.length);
            _this.kan_list.tu_type=file.name.substring(file.name.lastIndexOf("."), file.name.length)
            //转base64
            reader.onload = function(e) {
                _this.kan_list.img_url=e.target.result
                _this.kan_list.b64=e.target.result.substring(
                    e.target.result.indexOf(",") + 1,
                    e.target.result.length
                )
            };
            reader.readAsDataURL(file);
        },
        xuanze_cangku(){
            let id=this.kan_list.cangku_id!=undefined&&this.kan_list.cangku_id.length!=0?this.kan_list.cangku_id.split(','):[]
            let list=[]
            id.forEach(item=>{
                list.push({id:item})
            })
            query_ent_site_tree({data:{
                ent_id:this.$ent_id()
            }}).then(res=>{

                if(res.data.code==200){
                    let date=JSON.parse(res.data.body.data)

                    function xunhuan(data){
                        data.forEach(item=>{
                            if(item.children!=undefined&&item.children.length!=0){
                                item['disabled']=true
                                xunhuan(item.children)
                            }
                        })
                    }
                    xunhuan(date)

                    this.$refs.cangku.jichu(date,'仓库选择',list,'多选','没关联')
                }else if(res.data.code==500){}
            })
        },
        chuanzhi(data){

            // 10.18+'-'+item.id把这玩意去掉了，  梦颖
            this.kan_list.cangku_name_no=data.length!=0?data.map(item=>{return item.label}).join(','):''
            this.kan_list.cangku_name=data.length!=0?data.map(item=>item.label).join(','):''
            this.kan_list.cangku_id=data.length!=0?data.map(item=>item.id).join(','):''
        }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.product {
    height:100%;
    width:100%;
    .box2{
        position: relative;
        .dqbm{
            position: absolute;
            left: 0.31rem;
            top:-0.4rem;
            display: flex;
            align-items: center;
            p{
                color:#1A2533;
                font-size: 0.18rem;
            }
            div{
                height:0.25rem;
                width:0.49rem;
                border-radius: 0.02rem;
                border:0.01rem solid #888;
                font-size: 0.12rem;
                color:#888888;
                line-height: 0.25rem;
                text-align: center;
                margin-left: 0.1rem;
                cursor:pointer;
            }
        }
        .tit_center{
            position: absolute;
            left:50%;
            top:0;
            margin-left: -0.6rem;
          display: flex;
          align-items: center;
          div{
            display: flex;
            align-items: center;
            margin-right: 0.27rem;
            cursor:pointer;
            img{
              height:0.18rem;
              width:0.18rem;
              margin-right: 0.04rem;
            }
            p{
              font-size: 0.12rem;
              color:#4C4A4D;
            }
          }
        }
        padding: 0 0.31rem;
        .box2_mianbaoxie{
            // display: flex;
            // align-items: center;
            // justify-content: left;
            // margin-bottom: 0.1rem;
            // margin-top:0.6rem;
            display: flex;
            // align-items: center;
            width:3.4rem;
            flex-wrap: wrap;
            // justify-content: left;
            margin-top:0.6rem;
            margin-bottom: 0.1rem;
            div{
                display: flex;
                align-items: center;
                justify-content: left;
                cursor:pointer;
                p{
                    font-size: 0.14rem;
                }
                .p2{
                    color:#8d52e3;
                }
                .p1{
                    color:#1A2533;
                }
            }
        }
        .box_nei{
            height:0.42rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.12rem;
            background:#E7E8EB;
            div{
                display: flex;
                justify-content: left;
                align-items: center;
                p{
                    margin-right:0.12rem;
                }
            }
            p{
                // width:0.75rem;
                // height:0.25rem;
                padding: 0 0.13rem;
                border-radius:0.02rem;
                color:#888888;
                border:0.01rem solid #A9A9A9;
                text-align: center;
                line-height: 0.25rem;
                font-size: 0.12rem;
                background: #fff;
                border-radius: 0.03rem;
                cursor:pointer;
            }
            .sousuo_box{
                border:none;
                padding: 0;
                background:#E7E8EB;
                display: flex;
                align-items: center;
                position: relative;
                width:1rem;
                .el-icon-search{
                    font-size: 0.14rem;
                    position: absolute;
                    top:0.08rem;
                    left:0.08rem;
                }
                input{
                    height:0.27rem;
                    width:1rem;
                    padding: 0 0.3rem;
                    border:0.01rem solid #A9A9A9;
                    border-radius: 0.03rem;
                }
                .el-icon-circle-close{
                    font-size: 0.14rem;
                    position: absolute;
                    right:-0.48rem;
                    top:0.08rem;
                    display: none;
                }
                &:hover{
                    .el-icon-circle-close{
                        display: block;
                    }
                }
            }
        }
        .box2_liebiao{
            min-height:1.8rem;
            ul{
                padding: 0.15rem 0;
                li{
                    display: flex;
                    height:0.4rem;
                    justify-content: space-between;
                    align-items: center;
                    // padding: 0 0.35rem;
                    color:#1A2533;
                    font-size: 0.12rem;
                    font-weight: bold;
                    &:hover{
                        background: #f5f7fa;
                    }
                    // .img1{
                    //     height:0.11rem;
                    //     width:0.2rem;
                    // }
                    input{
                        -webkit-appearance: none;
                        width: 0.15rem!important;
                        height: 0.15rem!important;
                        display: inline-block;
                        margin: -0.03rem 0.32rem 0 0.1rem!important;
                        cursor: pointer;
                        vertical-align: middle;
                        background:url(../../assets/kaung_kongxin.png) no-repeat;
                        &:checked {
                            background: url(../../assets/kuang_duigou.png) no-repeat;
                            background-size: 100%;
                        }
                        background-size: 100%;
                        outline-color:none;
                        outline-style :none;
                        outline-width:none;
                    }
                    p{
                        display: flex;
                        align-items: center;
                        img{
                            height:0.16rem;
                            width: 0.16rem;
                            cursor: pointer;
                            margin-right:0.1rem;
                            margin-left:0.1rem;
                        }
                    }
                    .box2_liebiao_tit{
                        font-size: 0.12rem;
                        // color:#A5C6FD;
                        margin-left: 0.28rem;
                        cursor:pointer;
                        display: flex;
                        align-items: center;
                    }
                    div{
                        display: flex;
                        align-items: center;
                        p{
                            display: flex;
                            color: darkgrey;
                            align-items: center;
                            font-size: 0.12rem;
                            margin-left: 0.28rem;
                            cursor:pointer;
                            &:hover{
                                color: rgb(52, 53, 51);
                            }
                        }
                    }
                }
            }
        }
    }
    .tiao_bumen{
        height:3.79rem;
        width:6.57rem;
        background: #E7E8EB;
        margin: 0 auto;
        overflow-y:auto;
        &::-webkit-scrollbar {
            width: 0.2rem;
            height: 0.08rem;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.12rem;
            border: 0.06rem solid rgba(0, 0, 0, 0);
            box-shadow: 0.08rem 0 0 #A5ADB7 inset;
        }

        &::-webkit-scrollbar-thumb:hover {
            box-shadow: 0.08rem 0 0 #4A4A4A inset;
        }
        .mianbao_box{
            margin-left: 0.35rem;
            padding-top:0.19rem;
            display: flex;
            div{
                display: flex;
                align-items: center;
                cursor:pointer;
                p{
                    font-size: 0.14rem;
                }
                .p2{
                    color:#8d52e3;
                }
                .p1{
                    color:#4C4A4D;
                }
            }
        }
        ul{
            padding-top: 0.21rem;
            li{
                display: flex;
                height:0.4rem;
                justify-content: space-between;
                align-items: center;
                padding: 0 0.35rem;
                cursor:pointer;
                &:hover{
                    background: #ccc;
                }
                div{
                    display: flex;
                    align-items: center;
                    img{
                        display:block;
                        margin-right: 0.09rem;
                    }
                    input{
                        -webkit-appearance: none;
                        width: 0.15rem!important;
                        height: 0.15rem!important;
                        display: inline-block;
                        margin: -0.03rem 0.32rem 0 0.1rem!important;
                        cursor: pointer;
                        vertical-align: middle;
                        background:url(../../assets/kaung_kongxin.png) no-repeat;
                        &:checked {
                            background: url(../../assets/kuang_duigou.png) no-repeat;
                            background-size: 100%;
                        }
                        background-size: 100%;
                        outline-color:none;
                        outline-style :none;
                        outline-width:none;
                    }
                }
                .img1{
                    height:0.11rem;
                    width:0.2rem;
                }
            }
        }
    }
    .foote{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.21rem;
        p{
            margin: 0 0.23rem;
            height:0.43rem;
            width:1.36rem;
            border-radius: 0.02rem;
            font-size: 0.14rem;
            line-height: 0.43rem;
            text-align: center;
            font-weight: bold;
            cursor:pointer;
        }
        .queding{
            color:#fff;
            background: #8d52e3;
        }
        .quxiao{
            color: #888888;
            border:0.01rem solid #888888;
            background: #fff;
        }
    }
    .tiao_bumena{
        display: flex;
        align-items: center;
        justify-content:center;
        .xuanze,.yixuan{
            height:3.54rem;
            width:3.85rem;
            background: #E7E8EB;
            margin: 0 0.11rem;
            margin-top: 0.2rem;
            position: relative;
            .tit{
                position: absolute;
                left:0;
                top:-0.3rem;
                font-size: 0.14rem;
                color:#1A2533;
            }
            .xuanze_sousuo{
                position: relative;
                i{
                    position: absolute;
                    top:0.09rem;;
                    left: 0.32rem;
                }
                input{
                    height:0.3rem;
                    width:2.74rem;
                    border:none;
                    background:#fff;
                    padding: 0 0.33rem;
                    margin: 0 auto;
                    display: block;
                    margin-top: 0.15rem;
                    border-radius:0.02rem;
                }
            }
            .mianbaoxie{
                display: flex;
                align-items: center;
                justify-content: left;
                margin-bottom: 0.1rem;
                margin-left: 0.23rem;
                margin-top:0.12rem;
                div{
                    display: flex;
                    align-items: center;
                    cursor:pointer;
                    p{
                        font-size: 0.14rem;
                    }
                    .p2{
                        color:#8d52e3;
                    }
                    .p1{
                        color:#1A2533;
                    }
                }
            }
            .quanxuan{
                display: flex;
                align-items: center;
                margin-left: 0.24rem;
                input{
                    -webkit-appearance: none;
                    width: 0.18rem!important;
                    height: 0.17rem!important;
                    display: inline-block;
                    margin: -0.03rem 0.05rem 0 0!important;
                    cursor: pointer;
                    vertical-align: middle;
                    background:url(../../assets/noccc.png) no-repeat;
                    &:checked {
                        background: url(../../assets/okblue.png) no-repeat;
                    }
                    outline-color:none;
                    outline-style :none;
                    outline-width:none;
                }
                span{
                    color:#1A2533;
                    font-size: 0.12rem;
                }
            }
            .nei_box{
                width:3.45rem;
                margin: 0 auto;
                max-height: 2.4rem;
                overflow:auto;
                // overflow-y: hidden;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    width: 0.19rem;
                    height: 0;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 0.12rem;
                    border: 0.06rem solid rgba(0, 0, 0, 0);
                    box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                }

                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: 0.08rem 0 0 #4A4A4A inset;
                }
                .liebiao{
                    width:3.25rem;
                    margin: 0 auto;
                    ul{
                        li{
                            display: flex;
                            height:0.3rem;
                            justify-content: space-between;
                            align-items: center;
                            color:#1A2533;
                            font-size: 0.12rem;
                            padding-left: 0.1rem;
                            cursor:pointer;
                            &:hover{
                                background: #eee;
                            }
                            .img1{
                                height:0.11rem;
                                width:0.2rem;
                            }
                            input{
                                -webkit-appearance: none;
                                width: 0.18rem!important;
                                height: 0.17rem!important;
                                display: inline-block;
                                margin: -0.03rem 0.05rem 0 0!important;
                                cursor: pointer;
                                vertical-align: middle;
                                background:url(../../assets/noccc.png) no-repeat;
                                &:checked {
                                    background: url(../../assets/okblue.png) no-repeat;
                                }
                                outline-color:none;
                                outline-style :none;
                                outline-width:none;
                            }
                        }
                    }
                }
            }
        }
        .yixuan{
            .nei_box{
                width:3.45rem;
                margin: 0 auto;
                max-height: 3.50rem;
                overflow:auto;
                // overflow-y: hidden;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    width: 0.19rem;
                    height: 0;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 0.12rem;
                    border: 0.06rem solid rgba(0, 0, 0, 0);
                    box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                }

                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: 0.08rem 0 0 #4A4A4A inset;
                }
                .liebiao{
                    width:3.25rem;
                    padding: 0.2rem 0;
                    margin: 0 auto;
                    ul{
                        li{
                            display: flex;
                            height:0.3rem;
                            justify-content: space-between;
                            align-items: center;
                            color:#1A2533;
                            font-size: 0.12rem;
                            padding-left: 0.1rem;
                            cursor:pointer;
                            &:hover{
                                background: #eee;
                            }
                            .img1{
                                height:0.11rem;
                                width:0.2rem;
                            }
                            input{
                                -webkit-appearance: none;
                                width: 0.18rem!important;
                                height: 0.17rem!important;
                                display: inline-block;
                                margin: -0.03rem 0.05rem 0 0!important;
                                cursor: pointer;
                                vertical-align: middle;
                                background:url(../../assets/noccc.png) no-repeat;
                                &:checked {
                                    background: url(../../assets/okblue.png) no-repeat;
                                }
                                outline-color:none;
                                outline-style :none;
                                outline-width:none;
                            }
                            input[type="text"]:disabled{ background: #f1f1f1; }
                        }
                    }
                }
            }
        }
    }
}
</style>
